<template>
    <div id="f_cuowu">
        <div class="hadheight"></div>
        <Header />
        <div class="f_cuo base140">
			<div class="c-container"> 
				<div class="cont">
					<img src="../assets/images/quanbao404.png" >
					<!-- <div class="text">哎呀，页面丢失啦~</div>
					<a href="/" class="but" style="background: #000;">
						回到首页
					</a> -->
					<div class="tit basefont30">
						页面不见了， <span class="f_time">{{date}}</span><span style="color: #e11400">s</span>后自动跳转首页
					</div>
				</div>
			</div>
		</div>
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
export default {
   components:{
    Header,
    Footer,
  },
  data(){
	return {
		time: null,
		date: 3,
	}
  },
  mounted(){
    
    this.daojishi();
  },
  methods:{
	daojishi (){ //倒计时
		if(this.time == null){
			this.time = setTimeout(() => {
				this.time=null;
				this.date--;
				if(this.date>0){
					this.daojishi();
				}else{
					this.$router.push({path: "/"});
				}
				
			},1000);
			
		}
	}
  },
  beforeDestroy(){  //页面销毁的时候执行
	clearTimeout(this.time);
  }
}
</script>

<style  scoped lang="less">
.f_time{
	color: #e11400;
}
/* 404 */
	.f_cuo {
	  background-color: #fff;
	//   padding: 0 26px;
	  padding-left: 26px;
	  padding-right: 26px;
	}
	.f_cuo .cont {
	  display: flex;
	  flex-direction: column;
	  justify-content: center;
	  align-items: center;
	  text-align: center;
	}
	.f_cuo .cont img {
	  margin-bottom: ;
	  max-width: 100%;
	  margin-bottom: 52px;
	}
	@media (max-width: 1580px) {
	  .f_cuo .cont img {
	    margin-bottom: 30px;
	  }
	}
	@media (max-width: 972px) {
	  .f_cuo .cont img {
	    margin-bottom: 24px;
	  }
	}
	.f_cuo .cont .text {
	  font-size: 24px;
	  padding-top: 0;
	  margin-bottom: 60px;
	}
	@media (max-width: 1580px) {
	  .f_cuo .cont .text {
	    margin-bottom: 40px;
	  }
	}
	@media (max-width: 972px) {
	  .f_cuo .cont .text {
	    margin-bottom: 40px;
	  }
	}
	@media (max-width: 748px) {
	  .f_cuo .cont .text {
	    margin-bottom: 30px;
	  }
	}
	.f_cuo .cont .but {
	  width: 180px;
	  height: 50px;
	  color: #fff;
	  border-radius: 45px;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  font-size: 16px;
	  background-size: 100% 100%;
	}
	@media (max-width: 1580px) {
	  .f_cuo .cont .but {
	    font-size: 14px;
	    width: 140px;
	    height: 45px;
	  }
	}
	.f_cuo .cont .tit {
	  /* font-size: 16px; */
	  font-size: 30px;
	  margin-top: 12px;
	}
	@media (max-width: 1580px) {
	  .f_cuo .cont .tit {
	    font-size: 26px;
	    margin-top: 16px;
	  }
	}
	@media (max-width: 567px) {
	  .f_cuo .cont .tit {
	    font-size: 22px;
	    // margin-top: 16px;
	  }
	}
</style>